@font-face {
  font-family: 'UndercurrentBTN';
  src: url('UndercurrentBTN.svg#UndercurrentBTN') format('svg'),
       url('UndercurrentBTN.ttf') format('truetype'),
       url('UndercurrentBTN.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Undercurrent_20BTN';
  src: url('Undercurrent_20BTN.eot');
  src: url('Undercurrent_20BTN.eot?#iefix') format('embedded-opentype'),
       url('Undercurrent_20BTN.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

