@font-face {
  font-family: "Bradley";
  src: url("Bradley.eot");
  src: url("Bradley.eot?#iefix") format("embedded-opentype"),
    url("Bradley.svg#BradleyHandITCStd-Bold") format("svg"),
    url("Bradley.ttf") format("truetype"), url("Bradley.woff") format("woff"),
    url("Bradley.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
